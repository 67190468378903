import React from 'react';
import './LogoStrip.css';
import logoEvo from '../../../assets/img/logos/logo-evo.png';
import logoHidro from '../../../assets/img/logos/logo-hidroenergia.png';
import logoLudfor from '../../../assets/img/logos/logo-ludfor.png';
import logoVorbe from '../../../assets/img/logos/logo-vorbe.png';
import logoCog from '../../../assets/img/logos/logo-cog.png';

const LogoStrip = () => {
    const logos = [logoEvo, logoHidro, logoLudfor, logoVorbe, logoCog];
    
    return (
        <div className="logo-container">
            <div className="logo-strip">
                {Array(50).fill().map((_, repeatIndex) =>
                    logos.map((logo, index) => (
                        <img key={`logo-${repeatIndex}-${index}`} src={logo} alt={`Logo ${index + 1}`} />
                    ))
                )}
            </div>
        </div>
    );
};

export default LogoStrip;