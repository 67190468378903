import React from 'react';
import './CardServicePackage.css';
import blueStrip1 from '../../../../assets/img/servicePackages/faixa_azul.png';
import { FaCheckCircle } from "react-icons/fa";
import { MdCheckCircle } from "react-icons/md";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import icoCheck from '../../../../assets/img/servicePackages/ico_check.png';

const CardServicePackage = ({
    title,
    benefits,
    fontSizeTextImage = '14px',
    cardWidth = 'calc(45% - 135px)',
    cardHeight = 'auto',
    imageWidth = '300px',
    imageHeight = '130px',
    imageTop = '-47px',
    imageLeft = '-60px',
    benefitsListMarginTop = '50px',
    fontSizeH2 = "clamp(0.625rem, 2vw, 0.875rem)",
    fontSizebenefits = "clamp(0.625rem, 2vw, 0.875rem)",
    fontSizeAdditional = "clamp(0.625rem, 2vw, 0.875rem)",
    textTransform = 'translate(8%, 75%)',
    additionalBenefits = [
        "• Monitoramento de medidor (es)",
        "• Supervisão de 4 câmeras",
        "• Supervisão de 8 câmeras",
        "• Supervisão de 16 câmeras",
    ],
}) => {
    return (
        <div className="card-service-package" style={{ width: cardWidth, height: cardHeight }}>
            <div className="image-container" style={{ position: 'relative', height: imageHeight }}>
                <img
                    className="blue-strip-package"
                    src={blueStrip1}
                    alt="strip"
                    style={{
                        width: imageWidth,
                        height: imageHeight,
                        top: imageTop,
                        left: imageLeft,
                        position: 'absolute',
                        zIndex: 1,
                    }}
                />
                <div className="blue-strip-package-text" style={{ fontSize: fontSizeTextImage, position: 'absolute', top: '5%', left: '12px', zIndex: 2, transform: textTransform, }}>
                    {title}
                </div>
            </div>
            <div className="benefits-list" style={{ marginTop: benefitsListMarginTop }}>
                {benefits.map((benefit, index) => (
                    <div key={index} className="benefit-item">
                        <img
                            src={icoCheck}
                            alt="Check Icon"
                            style={{
                                width: 'clamp(0.725rem, 0.9vw, 0.975rem)',
                                height: 'clamp(0.725rem, 0.9vw, 0.975rem)',
                                marginRight: '5px',
                                marginLeft: '15px',
                                borderRadius: '50%',
                            }}
                        />
                        <span style={{ fontSize: fontSizebenefits }}>{benefit}</span>
                    </div>
                ))}
                <h2 style={{ fontSize: fontSizeH2 }}>Quer complementar seu plano?</h2>
                <h3 style={{ fontSize: fontSizeH2 }}>Inclua:</h3>
                <div className="additional-benefits" style={{ fontSize: fontSizeAdditional }}>
                    {additionalBenefits.map((benefit, index) => (
                        <span key={index}>{benefit}</span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CardServicePackage;