import React from 'react';
import './FooterSection.css';
import footerLogo from '../../../assets/img/logos/footer-logo.svg';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    const handleNavigation = (path, offset) => {
        navigate(path);
        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        });
    };

    return (
        <footer>
            <div className="footer-container">
                <div id="footer_content">
                    <div id="footer_image">
                        <img src={footerLogo} alt="COG" />           
                    </div>
                    
                    <ul className="footer-list">
                        <li>
                            <h3>Mapa do site</h3>
                        </li>
                        <li>
                            <span onClick={() => handleNavigation("/", 0)} className="footer-link">Home</span>
                        </li>
                        <li>
                            <span onClick={() => handleNavigation("/", 180 * window.innerHeight / 100)} className="footer-link">Serviços</span>
                        </li>
                        <li>
                            <span onClick={() => handleNavigation("/about", 0)} className="footer-link">Quem somos</span>
                        </li>
                        <li>
                            <span onClick={() => handleNavigation("/", 360 * window.innerHeight / 100)} className="footer-link">Contato</span>
                        </li>
                    </ul>
            
                    <ul className="footer-list">
                        <li>
                            <h3>Fale conosco</h3>
                        </li>
                        <li>
                            <a href="tel:+556236021795" className="footer-link"><b>+55 (62) 3602-1795</b></a>
                        </li>
                        <li>
                            <a href="https://www.google.com/maps?q=Rua+252,+nº+383+Qd.+3+Lt.+07,+Setor+Leste+universitário+-+Goiânia/GO" className="footer-link">Rua 252, nº 383 Qd. 3 Lt. 07</a>
                        </li>
                        <li>
                            <a href="https://www.google.com/maps?q=Setor+Leste+universitário+-+Goiânia/GO" className="footer-link">Setor Leste universitário - Goiânia/GO</a>
                        </li>
                    </ul>
                </div>
                <hr />
            
                <div id="footer_copyright">
                    <p>CENTRO DE OPERAÇÕES DE GERACAO RENOVAVEL LTDA</p>
                    <p>Política de Privacidade </p>
                    <p>Termos & condições</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;