import './ServicesSection.css';
import { Slide } from '../../ui/sliderSwiper';

const ServicesSection = () => {
    return (
        <div className='services-section'>
            <h2 className='services-title'> Nossos Serviços</h2>
            <div className='services-description'>
                <p className='services-p'>Cuidamos da sua energia para você</p>
                <p>focar no que realmente importa!</p>
            </div>
            <Slide/>
        </div>
    )
}

export default ServicesSection;