import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styled from 'styled-components';
import imgOperacao from '../../../assets/img/servicesSection/operacao_remota_dispositivos.png';
import portalCliente from '../../../assets/img/servicesSection/portal_do_cliente.png';
import analiseConta from '../../../assets/img/servicesSection/analise_conta_energia.png';
import suporteConcessonaria from '../../../assets/img/servicesSection/suporte_concessonaria.png';
import relatorioMensal from '../../../assets/img/servicesSection/relatorio_mensal.png';
import './SliderSwiper.css';

import {
    Navigation,
    Pagination,
    Mousewheel,
    Keyboard,
    Autoplay,
} from 'swiper/modules';
import CardService from '../cards/cardService';

const StyledSwiper = styled(Swiper)`
.swiper-slide {
       transition: transform 0.4s ease;
       padding: 60px 0; 
       margin-bottom: 30px;

   }

   .swiper-slide-active {
    transform: scale(${() => {
        if (typeof window !== "undefined") {
            if (window.matchMedia("(max-width: 768px)").matches) {
                return "1.0";
            } else if (window.matchMedia("(min-width: 769px) and (max-width: 1024px)").matches) {
                return "1.0";
            } else {
                return "1.0";
            }
        } else {
            return "1.0";
        }
    }});
  }
   .swiper-pagination {
       text-align: center;
       margin-bottom: 35px;
   }

   .swiper-pagination-bullet {
       width: 10px;
       height: 10px;
       background: #35363A;
       opacity: 1;
       
   }

   .swiper-pagination-bullet-active {
       background:rgb(251, 251, 253);
   }

   .swiper-button-next, .swiper-button-prev {
       color:rgb(240, 241, 245);
       border-radius: 100%;
       width: 30px;
       height: 30px;
       transition: all 0.3s ease;  
    
   }
   .swiper-button-next:hover, .swiper-button-prev:hover {
       width: 35px; 
       height: 35px;
}

   .swiper-button-next:after, .swiper-button-prev:after {
       font-size: 26px;
       font-weight: bold;   
   }

   @media (max-width: 768px) {
        .swiper-button-next, .swiper-button-prev {
            margin-right: -5px; 
            margin-left: -5px;
        }
    }
    @media (min-width: 769px) and (max-width: 992px) {
        .swiper-button-next, .swiper-button-prev {
            margin-right: 0px; 
            margin-left: 0px;
        }
    }
    @media (min-width: 992px) and (max-width: 1920px) {
        .swiper-button-next, .swiper-button-prev {
            margin-right: -15px; 
            margin-left: -15px;
        }
    }
    @media (min-width: 1921px) {
        .swiper-button-next, .swiper-button-prev {
        margin-right: -20px; 
        margin-left: -20px;
        }
    }

`;

export function Slide() {
    const [initialSlide, setInitialSlide] = useState(0);

    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const element = document.getElementById("slide-section");
        if (element) {
            const { top, bottom } = element.getBoundingClientRect();
            if (top < window.innerHeight && bottom >= 0) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    return (
        <div
            id="slide-section"
            className='slide-container'
            style={{
                opacity: isVisible ? 1 : 0,
                transition: "opacity 0.5s ease-in-out 0.2s",
                width: "100%",
                margin: "auto",
            }}
        >
            <StyledSwiper
                navigation={true}
                pagination={true}
                // autoplay={{
                //     delay: 4000,
                //     disableOnInteraction: false,
                // }}
                modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
                centeredSlides={false}
                initialSlide={initialSlide}
                breakpoints={{
                    480: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    992: { slidesPerView: 3 },
                    1536: { slidesPerView: 5 },
                }}
                style={{ width: "100%", height: 'auto', margin: 0 }}
            >

                <SwiperSlide style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CardService
                        title="Operação Remota de Dispositivos"
                        description="Assegure a eficiência da sua energia com nossa operação remota de dispositivos, subestações e inversores. Monitorando tudo para você em tempo real."
                        imgSrc={imgOperacao}
                        imgWidth="240px"
                        imgHeight="260px"
                    />
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CardService
                        title="Análise Conta de Energia"
                        description="Otimize seus custos com uma análise detalhada da sua conta de energia, identificando padrões de consumo e oportunidades de economia."
                        imgSrc={analiseConta}
                        imgWidth="280px"
                        imgHeight="280px"
                        paragraphMarginTop='0px'
                    />

                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CardService
                        title="Suporte junto à Concessionária"
                        description="Garanta a continuidade dos seus serviços com nosso suporte dedicado junto à concessionária de energia, resolvendo ocorrências e mantendo seu empreendimento sempre ativo."
                        imgSrc={suporteConcessonaria}
                        imgWidth="260px"
                        imgHeight="280px"
                        paragraphMarginTop='0px'
                    />
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CardService
                        title="Relatório Energético"
                        description="Mantenha-se informado com nossos relatórios de geração, consumo e ocorrências. Com insights valiosos para otimizar sua gestão energética."
                        imgSrc={relatorioMensal}
                        imgWidth="280px"
                        imgHeight="300px"
                        paragraphMarginTop='-20px'
                    />
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CardService
                        title="Portal do Cliente"
                        description="Acesse a eficiência do seu negócio a qualquer momento com nosso portal do cliente. Lá você tem acesso aos dados energéticos, relatórios e planos de manutenção de maneira prática e intuitiva."
                        imgSrc={portalCliente}
                        imgWidth="240px"
                        imgHeight="270px"
                        paragraphMarginTop='10px'
                    />
                </SwiperSlide>
            </StyledSwiper>
        </div>
    );
}