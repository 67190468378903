import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styled from 'styled-components';
import './SliderServicePackages.css'

import {
    Navigation,
    Pagination,
    Mousewheel,
    Keyboard,
    Autoplay,
} from 'swiper/modules';
import CardServicePackage from '../cards/cardServicePackage';

const StyledSwiper = styled(Swiper)`
.swiper-slide {
       transition: transform 0.4s ease;
       padding: 60px 0; 
       margin-bottom: 30px; 

   }
   
   .swiper-pagination {
       text-align: center;
       margin-bottom: 35px;
   }

   .swiper-pagination-bullet {
       width: 10px;
       height: 10px;
       background: #35363A;
       opacity: 1;
       
   }

   .swiper-pagination-bullet-active {
       background:rgb(251, 251, 253);
   }

   .swiper-button-next, .swiper-button-prev {
       color:rgb(240, 241, 245);
       border-radius: 100%;
       width: 30px;
       height: 30px;
       transition: all 0.3s ease;  
    
   }
   .swiper-button-next:hover, .swiper-button-prev:hover {
       width: 35px; 
       height: 35px;
}

   .swiper-button-next:after, .swiper-button-prev:after {
       font-size: 26px;
       font-weight: bold;   
   }

   @media (max-width: 768px) {
        .swiper-button-next, .swiper-button-prev {
            margin-right: -5px; 
            margin-left: -5px;
        }
    }
    @media (min-width: 769px) and (max-width: 992px) {
        .swiper-button-next, .swiper-button-prev {
            margin-right: 0px; 
            margin-left: 0px;
        }
    }
    @media (min-width: 992px) and (max-width: 1920px) {
        .swiper-button-next, .swiper-button-prev {
            margin-right: -15px; 
            margin-left: -15px;
        }
    }
    @media (min-width: 1921px) {
        .swiper-button-next, .swiper-button-prev {
        margin-right: -20px; 
        margin-left: -20px;
        }
    }

`;

export function SlideServicePackages() {
    const [initialSlide, setInitialSlide] = useState(0);

    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const element = document.getElementById("slide-section-packages");
        if (element) {
            const { top, bottom } = element.getBoundingClientRect();
            if (top < window.innerHeight && bottom >= 0) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const benefits = [
        "Operação e monitoramento remoto dos inversores",
        "Gestão e análise da conta de energia",
        "Suporte junto com a concessionária das ocorrências",
        "Suporte com fabricantes de equipamentos",
        "Relatório mensal de geração e consumo",
        "Plano de Manutenção",
        "Portal do Cliente",
    ];
    const benefits1 = [
        "Gestão e análise da conta de energia",
        "Suporte junto com a concessionária das ocorrências",
        "Suporte com fabricantes de equipamentos",
        "Relatório mensal de consumo",
        "Portal do Cliente"
    ];
    const benefits2 = [
        "Operação e monitoramento remoto das máquinas",
        "Gestão e análise da conta de energia",
        "Suporte junto com a concessionária das ocorrências",
        "Suporte com fabricantes de equipamentos",
        "Relatório mensal de geração e consumo",
        "Resumo diário de operação",
        "Operação remota da subestação e dispositivos",
        "Plano de manutenção",
        "Portal do Cliente"
    ];
    const benefits3 = [
        "Operação e monitoramento remoto das máquinas",
        "Gestão e análise da conta de energia",
        "Suporte junto com a concessionária das ocorrências",
        "Suporte com fabricantes de equipamentos",
        "Relatório mensal de geração e consumo",
        "Resumo diário de operação",
        "Operação remota da subestação e dispositivos",
        "Plano de manutenção",
        "Portal do Cliente"
    ];

    const additionalBenefits = [
        "• Monitoramento de medidor (es)",
        "• Supervisão de 4 câmeras",
        "• Supervisão de 8 câmeras",
        "• Supervisão de 16 câmeras",
        "• Operação remota da subestação e dispositivos"
    ];


    return (
        <div
            id="slide-section-packages"
            className='slide-container-packages'
            style={{
                opacity: isVisible ? 1 : 0,
                transition: "opacity 0.5s ease-in-out 0.2s",
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <h2 className='title-container-packages'>Nossos planos</h2>
            <StyledSwiper
                key="service-packages-swiper"
                navigation={true}
                pagination={true}
                // autoplay={{
                //     delay: 4000,
                //     disableOnInteraction: false,
                // }}
                modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
                centeredSlides={false}
                initialSlide={initialSlide}
                breakpoints={{
                    480: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    992: { slidesPerView: 3 },
                    1920: { slidesPerView: 4 },
                }}
                style={{ width: "100%", height: 'auto', margin: 0 }}
            >

                <SwiperSlide style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CardServicePackage
                        title="Usina Fotovoltaica (UFV)"
                        cardWidth="280px"
                        cardHeight="370px"
                        benefits={benefits}
                        fontSizeTextImage='12px'
                        imageWidth='350px'
                        imageHeight='130px'
                        imageTop='-50px'
                        textTransform='translate(2%, 75%)'
                        imageLeft='-65px'
                        fontSizeH2="10px"
                        fontSizebenefits='10px'
                        fontSizeAdditional="10px"
                        additionalBenefits={additionalBenefits}
                    />

                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CardServicePackage
                        title="Unidade Consumidora"
                        cardWidth="280px"
                        cardHeight="370px"
                        benefits={benefits1}
                        fontSizeTextImage='12px'
                        imageWidth='350px'
                        imageHeight='130px'
                        imageTop='-50px'
                        textTransform='translate(2%, 75%)'
                        imageLeft='-65px'
                        fontSizeH2="10px"
                        fontSizebenefits='10px'
                        fontSizeAdditional="10px"
                        additionalBenefits={additionalBenefits}
                    />

                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CardServicePackage
                        title="Central Geradora Hidrelétrica (CGH)"
                        cardWidth="280px"
                        cardHeight="370px"
                        benefits={benefits2}
                        fontSizeTextImage='12px'
                        imageWidth='350px'
                        imageHeight='130px'
                        imageTop='-50px'
                        textTransform='translate(-4%, 75%)'
                        imageLeft='-65px'
                        fontSizeH2="10px"
                        fontSizebenefits='10px'
                        fontSizeAdditional="10px"
                    />
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CardServicePackage
                        title="Usina Terméletrica (UTE)"
                        cardWidth="280px"
                        cardHeight="370px"
                        benefits={benefits3}
                        fontSizeTextImage='12px'
                        imageWidth='350px'
                        imageHeight='130px'
                        imageTop='-50px'
                        textTransform='translate(8%, 75%)'
                        imageLeft='-65px'
                        fontSizeH2="10px"
                        fontSizebenefits='10px'
                        fontSizeAdditional="10px"
                    />
                </SwiperSlide>
            </StyledSwiper>
        </div>
    );
}