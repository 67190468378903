import './Navbar.css'
import { RxHamburgerMenu } from "react-icons/rx";
import { TfiClose } from "react-icons/tfi";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/img/logos/logo.png';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const currentPath = window.location.pathname;
    const navigate = useNavigate();
    const handleNavigation = (path, scrollHeight) => {
        navigate(path);
        if (isOpen) setIsOpen(false);

        if ((currentPath !== path) && (path === "/" || path === "/about")) {

            window.scrollTo(0, 0);
        }


        setTimeout(() => {
            window.scrollTo({
                top: scrollHeight,
                behavior: 'smooth',
            });
        }, 200);
    };

    return (
        <div className="header">
            <div className="nav-container">
                <img
                    src={logo}
                    alt="Logo"
                    className="logo"
                    onClick={() => handleNavigation("/", 0)}
                />

                <nav className="nav-links">
                    <span onClick={() => handleNavigation("/", 0)} >Inicio</span>
                    <span onClick={() => handleNavigation("/", 180 * window.innerHeight / 100)}>Serviços</span>
                    <span onClick={() => handleNavigation("/about", 0)} >Sobre Nós</span>
                    <span onClick={() => handleNavigation("/", 360 * window.innerHeight / 100)}>Contato</span>
                    <span className="client-portal-btn" onClick={() =>
                        window.open(
                            "https://painel.cog.com.br"
                        )
                    } >Portal do Cliente</span>
                </nav>

                <button className="menu-button" onClick={toggleMenu}>
                    {isOpen ? <TfiClose size={22} color='white' /> : <RxHamburgerMenu size={25} color='white' />}
                </button>

                {isOpen && (
                    <div className="mobile-menu" ref={menuRef}>
                        <span onClick={() => handleNavigation("/", 0)}>Inicio</span>
                        <span onClick={() => handleNavigation("/", 180 * window.innerHeight / 100)}>Serviço</span>
                        <span onClick={() => handleNavigation("/about", 0)}>Sobre Nós</span>
                        <span onClick={() => handleNavigation("/", 360 * window.innerHeight / 100)}>Contato</span>
                        <span className="client-portal-btn" onClick={() =>
                            window.open(
                                "https://painel.cog.com.br"
                            )
                        }>Portal do Cliente</span>
                    </div>
                )}
            </div>
        </div>
    )
}


export default Navbar;