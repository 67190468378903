import './ContactUsSection.css'
import imageContact from '../../../assets/img/otherImages/contact-img2.png';
import ContactForm from '../../ui/contactForm';
const ContactUsSection = () => {
    return (
        <div className="contact-section">
            <h2 className='contact-title'>
                Fale com um Especialista!
            </h2>
            <div className='contact-section-content'>
                <div className='contact-description'>
                    <p>
                        Garanta a segurança e eficiência dos seus ativos
                        de energia com o COG. Prevenção de falhas e
                        otimização do sistema em tempo real.
                    </p>
                    <img src={imageContact} alt='about contact' />
                </div>
                <ContactForm/>
            </div>
        </div>
    );
}

export default ContactUsSection;