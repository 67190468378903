import './TestimonialSection.css';
const TestimonialSection = () => {
    return (
        <div className="testimonial-section">
            <div className='testimonial-content'>
                <div className='tesmonial-text'>
                    <h2>CONFIANÇA  E <br />
                        CREDIBILIDADE<br />
                        EM CADA SERVIÇO.</h2>
                    <div className='testimonial-description'>
                        <h3>Asssista a Jornada de nosso Cliente</h3>
                        <p>Epitácio confiou na nossa consultoria para a<br />
                            conexão de sua subestação. Hoje ele é parte da<br />
                            nossa equipe e possui sua usina em pleno<br />
                            funcionamento monitorada pela equipe COG.
                        </p>
                    </div>
                </div>
                <div className='testimomial-video'>

                </div>
            </div>
        </div>
    )
}

export default TestimonialSection;