import './ServicePackages.css';
import { useState, useEffect } from 'react';
import iconConsumidora from '../../../assets/img/servicePackages/icone_consumidora.png';
import iconFoltovoltaica from '../../../assets/img/servicePackages/icone_foltovoltaica.png';
import iconHidreletrica from '../../../assets/img/servicePackages/icone_hidreletrica.png';
import iconTermeletrica from '../../../assets/img/servicePackages/icone_termeletrica.png';
import CardServicePackage from '../../ui/cards/cardServicePackage';
import { useNavigate } from 'react-router-dom';

const ServicePackages = () => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [clicked, setClicked] = useState(false);

    const toggleDescription = (index) => {
        setActiveIndex(index); 
        setClicked(true);
    };


    const navigate = useNavigate();
    const handleNavigation = (path, scrollHeight) => {
        navigate(path);
        window.scrollTo({
            top: scrollHeight,
            behavior: 'smooth',
        });

    }

    useEffect(() => {
        if (!clicked) { 
            const interval = setInterval(() => {
                setActiveIndex(prevIndex => (prevIndex + 1) % plans.length);
            }, 3000); 

            return () => clearInterval(interval); 
        }
    }, [clicked]); 


    const plans = [
        {
            title: "Plano Usina Solar",
            benefits: [
                "Operação e monitoramento remoto dos inversores",
                "Gestão e análise da conta de energia",
                "Suporte junto com a concessionária das ocorrências",
                "Suporte com fabricantes de equipamentos",
                "Relatório mensal de geração e consumo",
                "Plano de Manutenção",
                "Portal do Cliente",
            ],
            additionalBenefits: [
                "• Monitoramento de medidor (es)",
                "• Supervisão de 4 câmeras",
                "• Supervisão de 8 câmeras",
                "• Supervisão de 16 câmeras",
                "• Operação remota da subestação e dispositivos"
            ],
        },
        {
            title: "Unidade Consumidora",
            benefits: [
                "Gestão e análise da conta de energia",
                "Suporte junto com a concessionária das ocorrências",
                "Suporte com fabricantes de equipamentos",
                "Relatório mensal de consumo",
                "Portal do Cliente"
            ],
            additionalBenefits: [
                "• Monitoramento de medidor (es)",
                "• Supervisão de 4 câmeras",
                "• Supervisão de 8 câmeras",
                "• Supervisão de 16 câmeras",
                "• Operação remota da subestação e dispositivos"
            ],
        },
        {
            title: "Plano CGH",
            benefits: [
                "Operação e monitoramento remoto das máquinas",
                "Gestão e análise da conta de energia",
                "Suporte junto com a concessionária das ocorrências",
                "Suporte com fabricantes de equipamentos",
                "Relatório mensal de geração e consumo",
                "Resumo diário de operação",
                "Operação remota da subestação e dispositivos",
                "Plano de manutenção",
                "Portal do Cliente"
            ],
        },
        {
            title: "Plano UTE",
            benefits: [
                "Operação e monitoramento remoto das máquinas",
                "Gestão e análise da conta de energia",
                "Suporte junto com a concessionária das ocorrências",
                "Suporte com fabricantes de equipamentos",
                "Relatório mensal de geração e consumo",
                "Resumo diário de operação",
                "Operação remota da subestação e dispositivos",
                "Plano de manutenção",
                "Portal do Cliente"
            ],
        }
    ];


    return (
        <div className='service-packages-container'>
            <div className='service-packages'>
                <div className='card1'>
                    <h2>Nossos Planos</h2>
                    <div className='plans'>
                        {[
                            { img: iconFoltovoltaica, title: "Usina Fotovoltaica (UFV)", desc: "Para uma maior eficiência de sua usina fotovoltaica, oferecemos um plano especial. Acompanhe, detecte falhas e otimize sua geração de energia com os nossos recursos." },
                            { img: iconConsumidora, title: "Unidade Consumidora", desc: "Para uma maior eficiência do seu empreendimento, oferecemos um plano especial. Acompanhe, detecte falhas e controle seu consumo energético com os nossos recursos." },
                            { img: iconHidreletrica, title: "Central Geradora Hidrelétrica (CGH)", desc: "Para uma maior eficiência da sua CGH, oferecemos um plano especial. Acompanhe, detecte falhas e controle sua geração de energia com os nossos recursos." },
                            { img: iconTermeletrica, title: "Usina Terméletrica (UTE)", desc: "Para uma maior eficiência da sua UTE, oferecemos um plano especial. Acompanhe, detecte falhas e controle sua geração de energia com os nossos recursos." }
                        ].map((plan, index) => (
                            <div
                                key={index}
                                className={`plan-item ${activeIndex === index ? 'active' : ''}`}
                                onClick={() => {
                                    toggleDescription(index);
                                    setClicked(true);
                                }}
                            >
                                <img src={plan.img} alt={plan.title} />
                                <div className='description'>
                                    <span>{plan.title}</span>
                                    {activeIndex === index && <p>{plan.desc}</p>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {activeIndex !== null && (
                    <CardServicePackage
                        title={plans[activeIndex].title}
                        description={plans[activeIndex].desc}
                        benefits={plans[activeIndex].benefits}
                        additionalBenefits={plans[activeIndex].additionalBenefits}
                    />
                )}
            </div>
            <div className='talk-spaceman'>
                <button onClick={() => handleNavigation("/", 360 * window.innerHeight / 100)}>Fale com um especialista</button>
            </div>
        </div>

    )
}

export default ServicePackages;