import React, { useState } from 'react';
import './ContactForm.css';
import axios from 'axios';


const ContactForm = () => {

    const [formData, setFormData] = useState({ 
        name: '',
        email: '',
        phone: '',
        role: '',
        company: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://127.0.0.1:8000/contact', formData);
            alert(response.data.message);
            console.log(response.data);

        } catch (error) {
            console.error('Ocorreu um erro ao enviar o formulário!', error);
        }
    };

    return (
        <div className="gradient-border">
            <form className="contact-form" onSubmit={handleSubmit}>
                <label htmlFor="name">Nome:</label>
                <input type="text" name="name"  value={formData.name} onChange={handleChange} required />

                <label htmlFor="email">E-mail:</label>
                <input type="email" name="email"  value={formData.email} onChange={handleChange} required />

                <label htmlFor="phone">Telefone:</label>
                <input type="tel" name="phone"  value={formData.phone} onChange={handleChange} required />

                <label htmlFor="role">Cargo:</label>
                <input type="text" name="role"  value={formData.role} onChange={handleChange} required />

                <label htmlFor="company">Empresa:</label>
                <input type="text" name="company"  value={formData.company} onChange={handleChange} required />

                <button type="submit">Agendar Demonstração</button>
            </form>
        </div>
    );
}

export default ContactForm;