import { SlideServicePackages } from '../../ui/sliderServicePackages'
import './ServicePackagesResponsive.css'


const ServicePackagesResponsive = () => {
    return (
        <div className='service-packages-responsive'>
                <SlideServicePackages/>
        </div>
    )
}   

export default ServicePackagesResponsive