import './HeroSection.css';

const HeroSection = () => {
  return (
    <div className='hero-section'>
      <div className='hero-section-content'>
      <div className='card'>
        <h1>
          MONITORAMENTO DE <br />
          ATIVOS EM TEMPO<br />
          REAL
        </h1>
        <h2>
          Eficiência e Segurança Energética <br />
          na Palma da sua Mão

        </h2>

      </div>
      </div>

    </div>
  )
}

export default HeroSection;