import React, { useEffect, useRef } from 'react';
import './InfoSection.css';
import logoEvo from '../../../assets/img/logos/logo-evo.png';
import logoHidro from '../../../assets/img/logos/logo-hidroenergia.png';
import logoLudfor from '../../../assets/img/logos/logo-ludfor.png';
import logoVorbe from '../../../assets/img/logos/logo-vorbe.png';
import logoCog from '../../../assets/img/logos/logo-cog.png';
import LogoStrip from '../../ui/LogoStrip';

import monitoramentoAtivos from '../../../assets/videos/monitoramento_ativos.mp4';
import operacaoRemotaDispositivos from '../../../assets/videos/operacao_remota_dispositivos.mp4';
import portalCliente from '../../../assets/videos/portal_cliente.mp4';

import thumbnails1 from '../../../assets/videos/thumbnails/thumbnails1.jpg';
import thumbnails2 from '../../../assets/videos/thumbnails/thumbnails2.jpg';
import thumbnails3 from '../../../assets/videos/thumbnails/thumbnails3.jpg';

const InfoSection = () => {

    const videoRefs = useRef([]);

    useEffect(() => {
        const videos = videoRefs.current;

        const playNextVideo = (currentIndex) => {
            const nextIndex = (currentIndex + 1) % videos.length;
            videos[currentIndex].pause();
            videos[nextIndex].play();
        };

        const eventHandlers = videos.map((video, index) => {
            const handler = () => playNextVideo(index);
            video.addEventListener('ended', handler);
            return { video, handler };
        });

        if (videos.length > 0) {
            videos[0].muted = true;
            videos[0].play();
        }

        return () => {
            eventHandlers.forEach(({ video, handler }) => {
                video.removeEventListener('ended', handler);
            });
        };
    }, []);

    return (
        <div className='info-section'>
            <LogoStrip />
            <div className="video-container">
                <div className="video-item-container">
                    <div className="video-title">MONITORAMENTO DE ATIVOS</div>
                    <video ref={el => videoRefs.current[0] = el} className="video-item" muted poster={thumbnails1}>
                        <source src={monitoramentoAtivos} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div className="video-item-container">
                    <div className="video-title">PORTAL DO CLIENTE</div>
                    <video ref={el => videoRefs.current[1] = el} className="video-item" muted poster={thumbnails2}>
                        <source src={portalCliente} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div className="video-item-container">
                    <div className="video-title">OPERAÇÃO REMOTA DE DISPOSITIVOS</div>
                    <video ref={el => videoRefs.current[2] = el} className="video-item" muted poster={thumbnails3}>
                        <source src={operacaoRemotaDispositivos} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    );
};

export default InfoSection;