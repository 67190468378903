import React from 'react';
import Navbar from '../components/sections/navbar';
import HeroSection from '../components/sections/HeroSection';
import InfoSection from '../components/sections/InfoSection';
import ServicesSection from '../components/sections/ServiceSection';
import ServicePackages from '../components/sections/ServicePackages';
import ServicePackagesResponsive from '../components/sections/ServicePackagesResponsive';
import ContactUsSection from '../components/sections/ContactUsSection';
import '../App.css';
import Footer from '../components/sections/FooterSection';

const Home = () => {
  return (
    <div className="container">
      <Navbar/>
      <HeroSection/>
      <InfoSection/>
      <ServicesSection/>
      <ServicePackages/>
      <ServicePackagesResponsive/>
      <ContactUsSection/>
      <Footer/>
    </div>
  );
}

export default Home;