import './AboutUsSection.css';
const AboutUsSection = () => {
    return (
        <div className="about-container">
            <div className="about-content">
                <h2>SOBRE NÓS</h2>
                <div className="about-text">
                    <div className='about-values'>
                    <h3>Valores:</h3>
                        <p>
                            prezamos pela aplicação da<br />
                            melhor técnica,<br />
                            maximizando nossos<br />
                            resultados.
                        </p>
                    </div>
                    <div className='about-vision'>
                        <h3>Visão:</h3>
                        <p>
                            ser uma referência em<br />
                            operação de<br />
                            empreendimentos
                        </p>
                    </div>
                    <div className='about-mission'>
                        <h3>Missão:</h3>
                        <p>
                            operar com segurança e <br />
                            economia nas instalações<br />
                            de geração e consumo.
                        </p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AboutUsSection;