import './CardService.css';
import blueStrip from '../../../../assets/img/servicesSection/faixa_azul.png';
const CardService = ({
  title,
  description,
  imgSrc,
  imgWidth = "240px",
  imgHeight = "260px",
  paragraphMarginTop = "20px"
}) => {
  return (
    <div className="card-services">
      <img className="img-service" src={imgSrc} alt="service" style={{ width: imgWidth, height: imgHeight }} />
      <img className="blue-strip" src={blueStrip} alt="strip" />
      <div className="blue-strip-text">{title}</div>
      <p style={{ marginTop: paragraphMarginTop }}>{description}</p>
    </div>
  );
};

export default CardService;