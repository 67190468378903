import './FoundersSection.css'
import evoImage from '../../../assets/img/founders/logo-evo.png';
import hidroenergiaImage from '../../../assets/img/founders/logo-hidroenergia.png';
import ludforImage from '../../../assets/img/founders/logo-ludfor.png';


const foundersData = [
    {
        title: "MATHEUS BATISTA",
        description: "Engenheiro Eletricista e de Segurança do Trabalho. Especialista em conexões de média tensão",
    },
    {
        title: "JOHNATAN DE ANDRADE",
        description: "Engenheiro Eletricista. Especialista em conexões de média tensão.",
    },
    {
        title: "GILSON GUIMARAES",
        description: "Engenheiro Eletricista. Especialista em soluções de energia.",
    },
    {
        title: "VICTOR BITENCOURT",
        description: "Engenheiro Eletricista. Especialista em soluções de energia.",
    },
    {
        title: "VINICIUS MURUSSI",
        description: "Engenheiro Eletricista. Especialista em Geração e Comercialização de Energia.",
    },
    {
        title: "EPITÁCIO BARZOTTO",
        description: "Vice Presidente do Conselho de Administração da Associação Brasileira de PCHs e CGHs.",
    }
];

const sponsorsData = [
    {
        image: evoImage,
        title: "EVO REPRESENTAÇÃO",
        description: "Especialista na venda de painéis elétricos.",
    },
    {
        image: hidroenergiaImage,
        title: "HIDROENERGIA",
        description: "Especialista em soluções de energia renovável.",
    },
    {
        image: ludforImage,
        title: "LUDFOR",
        description: "Expertise em soluções sustentáveis de energia.",
    }
];



const FoundersSection = () => {
    return (
        <div className="founders-section">
            <h2>Nossos Fundadores</h2>
            <div className="founders-section-content">
                <div className='sponsors'>
                        {sponsorsData.map((sponsor, index) => (
                            <div className='sponsors-card' key={index}>
                                <img src={sponsor.image} alt={sponsor.title} />
                                <h3>{sponsor.title}</h3>
                                <p>{sponsor.description}</p>
                            </div>
                        ))}
                </div>
                <div className="founders">
                <div className="founders-row">
                        {foundersData.slice(0, 3).map((founder, index) => (
                            <div className='founders-card' key={index}>
                                <h3>{founder.title}</h3>
                                <p>{founder.description}</p>
                            </div>
                        ))}
                    </div>
                    <div className="founders-row">
                        {foundersData.slice(3, 6).map((founder, index) => (
                            <div className='founders-card' key={index}>
                                <h3>{founder.title}</h3>
                                <p>{founder.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FoundersSection;