import './HistorySection.css'
import imgHistory from '../../../assets/img/otherImages/history-img.png';
const HistorySection = () => {
    return (
        <div className='history-section'>
            <div className='history-content'>
                <div className='text-div'>
                    <h2>Nossa História...</h2>
                    <p>O Centro de Operações da Geração (COG) foi fundado em 2022 por parceiros do
                        ramo de Energia com o objetivo de atender as necessidades de otimização,
                        monitoramento e segurança dos empreendimentos de nossos clientes. Dessa
                        forma, pensamos na melhor localização de atendimento e nos pilares éticos
                        essenciais para o pleno funcionamento da empresa</p>
                    <p>Escolhemos para nossa localização a Capital de Goiás, Goiânia, no Setor Leste
                        Universitário. No centro da inovação energética, estamos lado a lado de empresas
                        especialistas, contando com representantes de painéis elétricos de última geração,
                        com peritos em mercado livre de energia, com experts em projetos de subetações e
                        consultoria. Além disso, estamos próximos a Escola de Engenharia Elétrica,
                        Mecânica e de Computação da Universidade Federal de Goiás e da concessionária
                        de Energia, Equatorial</p>
                    <p>Nascemos em prol da união de nossa equipe em um único objetivo: oferecer a
                        melhor qualidade de serviço em monitoramento. Estamos comprometidos e não
                        estamos sozinhos. Com nossos pilares bem definidos: a seriedade, o respeito, a
                        ética e a transparência, trabalharemos incansavelmente para estar em constante
                        desenvolvimento e evolução.
                    </p>
                </div>
                <div className='image-div'>
                    <img src={imgHistory} alt="about history" />
                </div>
            </div>
        </div>
    );
};

export default HistorySection;