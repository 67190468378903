import React from 'react';
import Navbar from '../components/sections/navbar';
import AboutUsSection from '../components/sections/AboutUsSection';
import HistorySection from '../components/sections/HistorySection';
import '../App.css';
import TestimonialSection from '../components/sections/TestimonialSection';
import FoundersSection from '../components/sections/FoundersSection';

const About = () => {
  return (
    <div className="container">
      <Navbar/>
      <AboutUsSection/>
      <HistorySection/>
      <TestimonialSection/>
      <FoundersSection/>
    </div>
  );
}

export default About;